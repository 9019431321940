<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="loader-container" v-if="loading">
                    <div class="spinner-border spinner-border-lg loader"></div>
                    <p>Loading...</p>
                </div>
                <div v-else class="card kx-card table-responsive">

                    <div class="" style="display: flex; flex-direction: row;">
                        <h6 style="font-weight: bold;">Get Physio List By</h6>
                        <div class="form-check" style="margin-left: 20px;">
                            <input class="form-check-input" type="checkbox" id="by_pincode"
                                :checked="selectedOption === 'pincode'" @change="toggleOption('pincode')" />
                            <label class="form-check-label" for="by_pincode" style="font-size: 13px;">
                                By Pincode
                            </label>
                        </div>

                        <div class="form-check" style="margin-left: 10px;">
                            <input class="form-check-input" type="checkbox" id="by_area"
                                :checked="selectedOption === 'area'" @change="toggleOption('area')" />
                            <label class="form-check-label" for="by_area" style="font-size: 13px;">
                                By Area
                            </label>
                        </div>

                        <div class="form-check" style="margin-left: 10px;">
                            <input class="form-check-input" type="checkbox" id="by_city"
                                :checked="selectedOption === 'city'" @change="toggleOption('city')" />
                            <label class="form-check-label" for="by_city" style="font-size: 13px;">
                                By City
                            </label>
                        </div>
                    </div>
                    <div class="" style="display: flex; flex-direction: row; margin-top: 15px; width: 300px;">
                        <input v-if="selectedOption === 'pincode'" type="text" class="form-control" id="pincode"
                            v-model="physio_pincode" placeholder="Pincode"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '');" minlength="6" maxlength="6" />

                        <Multiselect v-if="selectedOption === 'area'" v-model="selectedMultiselectOption"
                            placeholder="Select your Area" :options="areaData" :searchable="true" :allow-empty="false"
                            label="label" track-by="value" @select="handleSearch"
                            @update:modelValue="handleMultiselectChange" />

                    <div v-if="selectedOption === 'city'" style="display: flex; flex-direction: row;">
                    <label class="form-select-label kx-label-secondary" for="city">City</label>
                    <select class="form-select" aria-label="City" v-model="physio_city" style="margin-left: 10px;width: 200px;">
                        <option v-for="option in cities" :value="option.city">
                            {{ option.city }}
                        </option>
                    </select>
                </div>

                        <button v-if="selectedOption === 'pincode' || selectedOption === 'city'" class="btn btn-primary" style="margin-left: 10px;"
                            @click="handleSearch">Check</button>
                    </div>



                    <table v-if="PhysiotherapyData.length" class="table text-left mt-3 kx-table">
                        <thead>
                            <tr>
                                <th scope="col">Name of Physiotherapist</th>
                                <th scope="col">Charges</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="doctor in PhysiotherapyData" :key="doctor.practitionerName">
                                <td>{{ doctor.practitionerName }}</td>
                                <td>
                                    <div v-for="charge in doctor.charges" :key="charge.heading">
                                        <span v-if="charge.charges && charge.charges > 0">
                                            <strong>{{ charge.heading }}</strong> : ₹{{ charge.charges }}
                                        </span>
                                    </div>
                                </td>

                                <td>
                                    <button class="btn btn-success btn-sm" @click="openModal(doctor)">
                                        Check Availability
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-else-if="apiMessage" class="text-center text-danger mt-3">
                        {{ apiMessage }}
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div v-if="showModal" class="modal" @click.self="closeModal">
        <div class="modal-content">
            <span class="close" @click="closeModal">&times;</span>
          <div class="d-flex justify-content-center">
            <p class="text-success">{{ res_message }}</p>
          </div>
            <div class="row mt-2">
                <div class="col-6">
                    <label for="select_date" class="" style="font-weight: bold;">
                        Select Date</label>
                    <VueDatePicker id="select_date" v-model="select_date" format="dd/MM/yyyy" :teleport="true">
                    </VueDatePicker>
                    <div>
                    </div>
                </div>
                <div class="col-6">
                    <label style="font-weight: bold;">Time Slots</label>
                    <div v-if="timeSlots.length > 0" class="time-slots-container">
                        <div v-for="slot in timeSlots" :key="slot.slot_no" class="time-slot-card"
                            :class="{ selected: slot.slot_no === slot_no }"
                            @click="selectTime(slot.from_time, slot.slot_no)">

                            <div class="slot-no">{{ slot.slot_no }}</div>
                            <div class="slot-time">{{ slot.from_time }}</div>
                        </div>
                    </div>
                    <div v-else style="font-weight: lighter; margin-top: 10px;">{{ apiMessage }}</div>
                </div>
                <div class="">
                    <button class="btn btn-primary btn-sm" style="float: right;" @click="bookAppointment">Book
                        Appointment</button>
                </div>
            </div>
        </div>

        <div v-if="showLoadingModal" class="model-loading" @click.self="closeLoadingModal">
            <div class="modal-loading-content text-center">
                <div class="spinner-border spinner-border-lg loader"></div>
                <p>Loading...</p>
            </div>
        </div>
        
    </div>

</template>

<script>
import axios from "axios";
import 'vue3-timepicker/dist/VueTimepicker.css';
import Multiselect from "@vueform/multiselect";

export default {
    name: "AssignPhysiotherapist",
    components: {
        Multiselect,
    },
    props: {
        msg: String,
    },
    data() {
        return {
            patient_data: {
                mobile_no: null,
                patient_full_name: "",
                gender: "",
                suburb: "",
                city: "",
                hospital: "Freelancer-Indore",
                appointment_type: "OPD",
                practitioner: null
            },

            resend_patient_data:{
                    age: "",
                    occupation: "",
                    health_scheme: "",
                    address: "",
                    google_location: "",
                    assign_physio_at_home: "",
                    pincode: null,
                    state: null,
                    mediclaim: "",
                    same_whatsapp_no: "",
                    whatsapp_no: "",
                    knee_lead_status: "",
                    language: "",
                    treatment_update: "",
                    physio_at_home_appointment_status: "",
                    camp: ""

            },
            selectedMultiselectOption: null,
            selectedOption: '',
            physio_pincode: '',
            physio_area: '',
            physio_city:'',
            PhysiotherapyData: [],
            loading: false,
            apiMessage: "",
            showModal: false,
            showLoadingModal: false,
            select_date: null,
            selectedTime: null,
            slot_no: null,
            timeSlots: [],
            areaData: [],
            cities:[],
            pincodeData: "",
            appointment_id: null,
            patient_id: null,
            patient_appointment_id:null,
            res_message: ""

        }
    },
    computed: {
        isFormValid() {
            return this.AddData.date !== "" && this.AddData.time !== "";
        },
    },

    watch: {
        select_date(newDate) {
        if (newDate) {
            this.showLoadingModal = true;
            const formattedDate = new Date(newDate).toISOString().split('T')[0];
            this.fetchTimeSlots(formattedDate);
        }
    },
        selectedMultiselectOption(newValue) {
            console.log("Watcher triggered:", newValue);
            if (!newValue || newValue.length === 0) {
                this.fetchPhysiotherapistsByArea(this.patient_data.city);
            }
        }
    },

    created() {
        this.get_data_from_lead();
        this.fetchSuburbByCity();
    },

    methods: {



        async get_cities() {
            try {
                const response = await axios.get("https://live-physio.kneexpert.in/api/method/sahaj.finalapi.get_city", {
                        headers: {
                            Authorization: '',
                        },
    
                    params: { mobile: this.$route.params.id },
                });
                 this.cities = response.data.message;
                 console.log("fd",response.data.message)

            } catch (error) {
                console.error("Error fetching lead data:", error);
            }
        },

        async handleMultiselectChange(newValue) {
            console.log("Multiselect value updated:", newValue);

            if (!newValue || newValue.length === 0) {
                console.log("Multiselect cleared. Fetching by city data:", this.patient_data.city);
                await this.fetchPhysiotherapistsByArea(this.patient_data.city);
            }
        },


        async handleAreaSelection(selectedArea) {
            if (selectedArea) {
                this.physio_area = selectedArea.value; // Update the selected area
                await this.fetchPhysiotherapistsByArea(this.physio_area); // Fetch physiotherapists for the selected area
            }
        },

        async fetchSuburbByCity() {
            try {
                const response = await axios.get(
                    `https://live-physio.kneexpert.in/api/method/sahaj.knee_lead_portal.get_suburb?city=${this.patient_data.city}`,
                    {
                        headers: {
                            Authorization: '',
                        },
                    }
                );
                if (response.data.data) {
                    this.areaData = response.data.data.map((suburb) => ({
                        label: suburb.zone,
                        value: suburb.name,
                    }));
                } else {
                    this.areaData = [];
                    console.warn("No suburbs found.");
                }
            } catch (error) {
                console.error("Error fetching suburb data:", error);
            }
        },

        async get_data_from_lead() {
            try {
                const response = await axios.get("knee_lead.api.get_single_knee_lead", {
                    params: { mobile: this.$route.params.id },
                });
                const leadData = response.data.data[0];

                // Set pincode and pre-fill the form
                this.patient_id = leadData.patient_id_physio_portal;
                this.pincodeData = leadData.pincode;
                this.patient_data.city = leadData.city;
                this.patient_data.mobile_no = leadData.mobile;
                this.patient_data.patient_full_name = leadData.patient_full_name;
                this.patient_data.gender = leadData.gender;
                this.patient_data.suburb = leadData.zone;
                this.resend_patient_data.age = leadData.age;
                this.resend_patient_data.address = leadData.address;
                this.resend_patient_data.camp = leadData.camp;
                this.resend_patient_data.google_location = leadData.google_location;
                this.resend_patient_data.health_scheme = leadData.health_scheme;
                this.resend_patient_data.knee_lead_status = leadData.knee_lead_status[0].name;
                this.resend_patient_data.assign_physio_at_home = leadData.assign_physio_at_home;
                this.resend_patient_data.mediclaim = leadData.mediclaim;
                this.resend_patient_data.occupation = leadData.occupation;
                this.resend_patient_data.physio_at_home_appointment_status = leadData.physio_at_home_appointment_status;
                this.resend_patient_data.language = leadData.language;
                this.resend_patient_data.pincode = leadData.pincode;
                this.resend_patient_data.same_whatsapp_no = leadData.same_whatsapp_no;
                this.resend_patient_data.state = leadData.state;
                this.resend_patient_data.treatment_update = leadData.treatment_update;
                this.resend_patient_data.whatsapp_no = leadData.whatsapp_no;


                await this.fetchSuburbByCity(this.patient_data.city);
                if (this.pincodeData) {
                    this.physio_pincode = this.pincodeData;
                    this.selectedOption = 'pincode';
                    await this.fetchPhysiotherapistsByPincode(this.physio_pincode);

                }
            } catch (error) {
                console.error("Error fetching lead data:", error);
            }
        },

        async handleSearch() {
    try {
        if (this.selectedOption === 'pincode' && this.physio_pincode) {
            // Fetch by Pincode
            await this.fetchPhysiotherapistsByPincode(this.physio_pincode);
        } else if (this.selectedOption === 'city' && this.physio_city) {
            // Fetch by City
            await this.fetchPhysiotherapistsByCity(this.physio_city);
        } else if (this.selectedOption === 'area' && this.selectedMultiselectOption) {
            // Fetch by Area
            await this.fetchPhysiotherapistsByArea(this.selectedMultiselectOption.value);
        } else {
            alert("Please enter or select valid data.");
        }
    } catch (error) {
        console.error("Error during search:", error);
        alert("An error occurred. Please try again.");
    }
},

        async fetchPhysiotherapistsByPincode(pincode) {
            this.loading = true;
            try {
                const response = await axios.get(
                    `https://live-physio.kneexpert.in/api/method/sahaj.knee_lead_portal.search_doctor_by_pincode?pincode=${pincode}`,
                    {
                        headers: {
                            Authorization: '',
                        },
                    }
                );

                if (response.data.message === 'Doctors Found') {
                    this.PhysiotherapyData = this.processData(response.data.data);
                    this.apiMessage = '';
                } else {
                    this.PhysiotherapyData = [];
                    this.apiMessage = response.data.message;
                }
            } catch (error) {
                console.error("Error fetching physiotherapists:", error);
                this.PhysiotherapyData = [];
                this.apiMessage = 'An error occurred while fetching data.';
            } finally {
                this.loading = false;
            }
        },

        async fetchPhysiotherapistsByCity(city) {
    this.loading = true;
    try {
        const response = await axios.get(
            `https://live-physio.kneexpert.in/api/method/sahaj.knee_lead_portal.search_doctor_by_city`,
            {
                params: { city },
                headers: {
                    Authorization: '',
                },
            }
        );

        if (response.data.message === 'Doctors Found') {
            this.PhysiotherapyData = this.processData(response.data.data);
            this.apiMessage = '';
        } else {
            this.PhysiotherapyData = [];
            this.apiMessage = response.data.message;
        }
    } catch (error) {
        console.error("Error fetching physiotherapists by city:", error);
        this.PhysiotherapyData = [];
        this.apiMessage = 'An error occurred while fetching data.';
    } finally {
        this.loading = false;
    }
},

        async fetchPhysiotherapistsByArea(area) {
            this.loading = true;
            try {
                // Determine the parameter based on the selection
                const paramKey = this.selectedMultiselectOption ? 'suburb' : 'city';
                const paramValue = encodeURIComponent(area);

                const response = await axios.get(
                    `https://live-physio.kneexpert.in/api/method/sahaj.knee_lead_portal.search_doctor_by_city?${paramKey}=${paramValue}`,
                    {
                        headers: {
                            Authorization: '',
                        },
                    }
                );

                if (response.data.message === 'Doctors Found') {
                    this.PhysiotherapyData = this.processData(response.data.data);
                    this.apiMessage = '';
                } else {
                    this.PhysiotherapyData = [];
                    this.apiMessage = response.data.message;
                }
            } catch (error) {
                console.error("Error fetching physiotherapists:", error);
                this.PhysiotherapyData = [];
                this.apiMessage = 'An error occurred while fetching data.';
            } finally {
                this.loading = false;
            }
        },

        processData(data) {
            return data.map((doctor) => {
                return {
                    name: doctor.name,
                    practitionerName: doctor.practitioner_name,
                    city: doctor.city,
                    charges: [
                        ...doctor.modality_charges.map((item) => ({
                            heading: `Modality Charges: ${item.modality}`,
                            charges: item.charges,
                        })),
                        ...doctor.physiotherapy_charges.map((item) => ({
                            heading: `Physiotherapy Charges: ${item.days}`,
                            charges: item.charges,
                        })),
                        ...doctor.manual_therapy_charges.map((item) => ({
                            heading: `Manual Therapy Charges: ${item.therapy}`,
                            charges: item.charges,
                        })),
                    ],
                };
            });
        },


        openModal(doctor) {
            this.showModal = true;
            this.patient_data.practitioner = doctor.name;
        },
        closeLoadingModal() {
            this.showLoadingModal = false;
        },
        closeModal() {
            this.showModal = false;
            this.select_date = null;
            this.timeSlots = [];

        },


        toggleOption(option) {
            if (option === 'pincode') {
                this.selectedOption = 'pincode';
                if (this.physio_pincode) {
                    this.fetchPhysiotherapistsByPincode(this.physio_pincode);
                }
            }else if (option === 'city') {
                this.selectedOption = 'city';
                if (this.physio_city) {
                    this.fetchPhysiotherapistsByCity(this.physio_city);
                }else{
                    this.apiMessage = "No doctors found for the given City"
                }
            }else if (option === 'area') {
                this.selectedOption = 'area';
                if (this.selectedMultiselectOption) {
                    this.fetchPhysiotherapistsByArea(this.selectedMultiselectOption);
                } else if (this.patient_data.city) {
                    this.fetchPhysiotherapistsByArea(this.patient_data.city);
                }
            }
        },

        async fetchTimeSlots(formattedDate) {
            if (!formattedDate) {
                this.timeSlots = [];
                this.apiMessage = "Please select a valid date.";
                this.showLoadingModal = false;
                return;
            }

            try {
                const response = await axios.get(
                    `https://live-physio.kneexpert.in/api/method/sahaj.appointment.get_availability_data?date=${formattedDate}&hospital=Freelancer-Indore&practitioner=${this.patient_data.practitioner}`,
                    {
                        headers: {
                            Authorization: '',
                        },
                    }
                );

                if (response.data.status_code === 200 && response.data.data.slot_details.length > 0) {
                    const slots = response.data.data.slot_details[0].avail_slot || [];

                    this.timeSlots = slots.map((slot) => ({
                        slot_no: slot.slot_no,
                        from_time: slot.from_time.substring(0, 5),
                    }));

                } else {
                    this.timeSlots = [];
                    this.apiMessage = "No time slots available.";
                }
            } catch (error) {
                console.error("Error fetching time slots:", error);
                this.apiMessage = "An error occurred while fetching time slots.";
            } finally {
        this.showLoadingModal = false;
    }
        },


        selectTime(from_time, slot_no) {
            this.selectedTime = `${from_time}:00`;
            this.slot_no = slot_no;
        },

        async bookAppointment() {
    if (!this.select_date || !this.selectedTime || !this.slot_no) {
        this.res_message = "Please select a date, time, and slot to book an appointment.";
        return;
    }

    const formattedDate = new Date(this.select_date).toISOString().split("T")[0];

    const requestData = {
        ...this.patient_data,
        appointment_date: formattedDate,
        appointment_time: this.selectedTime,
        slot_no: this.slot_no,
    };

    try {
        if (!this.patient_id) {
            const registerResponse = await axios.post(
                "https://live-physio.kneexpert.in/api/method/sahaj.knee_lead_portal.register_patient",
                requestData,
                {
                    headers: {
                        Authorization: "",
                    },
                }
            );

            if (registerResponse.data.data) {
                this.patient_id = registerResponse.data.data.patient_id;
                this.patient_appointment_id = registerResponse.data.data.patient_appointment_id;
                this.res_message = `${registerResponse.data.message}`;
                await this.updateFields(this.patient_id);
            } else {
                this.res_message = "Patient registration failed.";
                return;
            }
        }

        if (this.patient_id) {
            await this.makeAppointmentAPI(requestData);
        }
    } catch (error) {
        console.error("Error during booking process:", error);
        alert("An error occurred while processing the appointment.");
    }
},


async makeAppointmentAPI(requestData) {
    try {

        const appointmentPayload = {
            patient: this.patient_id,
            appointment_date: requestData.appointment_date,
            appointment_time: requestData.appointment_time,
            slot_no: requestData.slot_no,
            practitioner: this.patient_data.practitioner,
            appointment_type: this.patient_data.appointment_type,
            hospital: this.patient_data.hospital,
        };

        const appointmentResponse = await axios.post(
            "https://live-physio.kneexpert.in/api/method/sahaj.knee_lead_portal.get_doctor_appointment",
            appointmentPayload,
            {
                headers: {
                    Authorization: "",
                },
            }
        );

        if (appointmentResponse.data.status_code === 200) {
            this.patient_appointment_id = appointmentResponse.data.message;
            this.res_message = `Appointment ID ${appointmentResponse.data.message} Booking Successfully`;
            await this.updateFields(this.patient_id);
            setTimeout(()=>{
            this.closeModal();
            this.res_message = "";
            this.select_date = null;
            this.timeSlots = [];
            },3000);
        } else {
            this.res_message = `${appointmentResponse.data.message}`;
            setTimeout(()=>{
            this.closeModal();
            this.res_message ="";
            this.select_date = null;
            this.timeSlots = [];
            },3000);
        }
    } catch (error) {
        console.error("Error Booking Appointment:", error);
        this.res_message = "An error occurred while booking the appointment.";
    }
},



async updateFields(patient_id) {
    try {
    const payloadData = {
        ...this.resend_patient_data,
            name: this.$route.params.id,
            patient_id_physio_portal:patient_id,
            patient_appointment_id:this.patient_appointment_id,
            patient_full_name: this.patient_data.patient_full_name,
            city:this.patient_data.city,
            gender:this.patient_data.gender,
            mobile_no:this.patient_data.mobile_no,
            zone:this.patient_data.suburb
        }
        const updateResponse = await axios.post(
            "knee_lead.knee_lead_details.update_knee_lead_basic_details",
            payloadData
        );
        this.res_message = `${updateResponse.data.message}`;
        this.get_data_from_lead();
    } catch (error) {
        console.error("Error updating patient details:", error);
        alert("Failed to update patient details.");
    }
},



    },
    mounted() {
        this.get_cities();
    },
};
</script>

<style scoped>

.text-success {
    color: green !important;
}

.text-danger {
    color: red !important;
}
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    /* Adjust as needed */
    text-align: center;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}

.kx-table {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.kx-table thead th {
    color: black;
    font-weight: bold;
    text-align: left;
    padding: 10px;
}

.kx-table tbody tr {
    background: white;
    transition: all 0.3s ease;
}

.kx-table tbody td {
    vertical-align: middle;
    font-size: 14px;
}

.kx-table .btn-outline-success {
    font-weight: bold;
    color: #28a745;
    border-color: #28a745;
    transition: background-color 0.3s, color 0.3s;
}

.kx-table .btn-outline-success:hover {
    background-color: #28a745;
    color: white;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 60%;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    z-index: 800;
}

.dp__menu {
    z-index: 900 !important;
    position: absolute !important; 
}
.modal {
    position: fixed;
    top: 0;
    padding-top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Modal background should stay below modal content */
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.close:hover {
    color: red;
}

.model-loading {
    position: fixed;
    top: 0;
    padding-top: 10px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.time-field {
    width: 100%;
}

.modal-loading-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    width: 100%;
    position: relative;
}

.time-slots-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* Adjust alignment */
    gap: 10px;
    /* Space between cards */
}

.time-slot-card {
    flex: 1 1 calc(20% - 16px);
    max-width: calc(22% - 15px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    background-color: #f5f5f5;
    transition: background-color 0.3s, color 0.3s;
}

.time-slot-card:hover {
    background-color: #d9edf7;
}

.time-slot-card.selected {
    background-color: #5bc0de !important;
    /* Selected background */
    color: black !important;
    font-weight: bold;
}

.time-slot-card.selected .slot-no{
    color: black;
    background-color: white;
}

.slot-no {
    width: 18px;          
    height: 18px;         
    border-radius: 50%;  
    background-color: #5bc0de; 
    color: white;  
    font-weight: bold;   
    display: flex;  
    align-items: center;  
    justify-content: center;
    margin: 0 auto; 
    font-size: 12px;
}
.slot-time{
    font-size: 12px;
    margin-top: 5px;
}

</style>
